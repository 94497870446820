import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TeslaService } from '../../../core/services/tesla.service';
import { DataProviderService } from '../../../core/services/data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'tes-init',
  standalone: true,
  imports: [],
  templateUrl: './init.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitComponent {
  constructor(
    private readonly teslaService: TeslaService,
    private readonly dataProviderService: DataProviderService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    const tesToken = this.activatedRoute.snapshot.queryParamMap.get('token') || '';
    this.dataProviderService.initWith(this.teslaService.getTokenData(tesToken));
    setTimeout(() => this.router.navigateByUrl('/part-pro-choice'), 3000);
  }
}
