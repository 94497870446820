import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AppConfig } from './app.config';

export const apikeyInterceptor: HttpInterceptorFn = (req, next) => {
  const config = inject(AppConfig);
  if (config.apikey && req.url.startsWith(config.url)) {
    const headers = new HttpHeaders({
      apikey: config.apikey
    });
    return next(
      req.clone({
        headers
      })
    );
  } else {
    return next(req);
  }
};
