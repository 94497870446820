import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CarPictureService {
  baseUrl = 'https://static-assets.tesla.com/configurator/compositor?context=design_studio_2';
  colors = ['$PR01', '$PPSB', '$PN01', '$PBSB', '$PPSW'];
  options = '$MT351,$W38A,$IPB2,' + this.colors[Math.floor(Math.random() * this.colors.length)];
  view = 'STUD_FRONT34';
  model = 'm3';
  size = 720;

  constructor() {}

  getUrl(params: { options?: string; view?: string; model?: string; size?: number } = {}): string {
    const { options, view, model, size } = params;
    return `${this.baseUrl}&options=${options || this.options}&view=${view || this.view}&model=${model || this.model}&size=${size || this.size}&crop=1100,500,414,300`;
  }
}
