import { Routes } from '@angular/router';
import { InitComponent } from './shared/pages/init/init.component';
import { PartProChoiceComponent } from './shared/pages/part-pro-choice/part-pro-choice.component';
import { SimulateComponent } from './shared/pages/simulate/simulate.component';
import { FinalizeComponent } from './shared/pages/finalize/finalize.component';

export const APP_ROUTES: Routes = [
  { path: 'init', component: InitComponent },
  { path: 'part-pro-choice', component: PartProChoiceComponent },
  { path: 'simulate', component: SimulateComponent },
  { path: 'finalize', component: FinalizeComponent },
  // Wildcard, to handle non-existing route. Can redirect to a 404 component
  { path: '**', redirectTo: '' }
];
