import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ParagraphComponent } from '../../layout/paragraph/paragraph.component';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, NgClass } from '@angular/common';
import { VehicleData } from '../../../core/models/vehicle-data.model';
import { IconComponent, PopinTriggerComponent } from 'ngx-mobilite-material';

@Component({
  selector: 'tes-vehicle-definition',
  standalone: true,
  imports: [ParagraphComponent, TranslateModule, NgClass, CurrencyPipe, PopinTriggerComponent, IconComponent],
  templateUrl: './vehicle-definition.component.html',
  styleUrl: './vehicle-definition.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VehicleDefinitionComponent {
  @Input() vehicleData!: VehicleData;
}
