<tes-paragraph [title]="'Tesla, ' + vehicleData.model">
  @for (line of vehicleData.literalLabel; track line; let i = $index) {
    <p [ngClass]="{ bold: i === 0 }">{{ line }}</p>
  }
  <ml-popin-trigger [title]="'vehicle-definition.price-explain' | translate" [buttonText]="'vehicle-definition.close' | translate">
    <div trigger class="vehicle-definition_price">
      <span>
        {{ 'vehicle-definition.price' | translate }}
      </span>
          <span class="bold">
        {{ vehicleData.totalPrice | currency:'EUR' }}
      </span>
      <ml-icon iconName="info"/>
    </div>
    <div class="vehicle-definition_popin-explanation">
      <ml-icon iconName="info"/>
      <p>
        {{ 'vehicle-definition.price-explanation' | translate }}
      </p>
    </div>
  </ml-popin-trigger>
</tes-paragraph>
