import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tes-paragraph',
  standalone: true,
  imports: [],
  templateUrl: './paragraph.component.html',
  styleUrl: './paragraph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParagraphComponent {
  @Input() title!: string;
}
