import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/layout/header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppConfig } from './app.config';

@Component({
  selector: 'tes-app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, HeaderComponent, TranslateModule],
  standalone: true
})
export class AppComponent {
  title = 'Application';

  constructor(readonly appConfig: AppConfig) {
    const node = document.createElement('script');
    node.setAttribute('nonce', 'NGINX_CSP_NONCE');
    node.setAttribute('src', appConfig.mobiboxUrl);
    node.setAttribute('type', 'module');
    node.setAttribute('async', 'true');
    node.setAttribute('partner', 'TESLA');
    node.setAttribute('name', 'mobi-box');
    node.setAttribute('logo-id', 'logo-cetelem');
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
