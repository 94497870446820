import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

interface Config {
  url: string;
  apikey: string;
  basepath: string;
  mobiboxUrl: string;
}

export interface IAppConfig {
  url: string;
  apikey: string;
  basepath: string;
  mobiboxUrl: string;
  load: () => Observable<Config>;
}

@Injectable()
export class AppConfig implements IAppConfig {
  public url = '';
  public apikey = '';
  public basepath = '';
  public mobiboxUrl = '';

  constructor(private readonly http: HttpClient) {}

  public load(): Observable<Config> {
    return this.http.get<Config>('./assets/config.json').pipe(
      tap({
        next: (config: Config) => {
          this.url = config?.url ?? '';
          this.apikey = config?.apikey ?? '';
          this.basepath = config?.basepath ?? '';
          this.mobiboxUrl = config?.mobiboxUrl ?? '';
        },
        error: (err: HttpErrorResponse) => {
          console.log(err.message);
        }
      })
    );
  }
}

export const initConfig =
  (config: AppConfig): (() => Observable<Config>) =>
  () =>
    config.load();
