import { Injectable } from '@angular/core';
import { TokenInformation } from '../models/token-information.model';
import { mergeMap, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {
  private triggerData$ = new ReplaySubject<Observable<TokenInformation>>(1);
  private data$: Observable<TokenInformation> = this.triggerData$.pipe(mergeMap(obs$ => obs$));

  initWith(observable: Observable<TokenInformation>): void {
    this.triggerData$.next(observable);
  }

  get(): Observable<TokenInformation> {
    return this.data$;
  }
}
