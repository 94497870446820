import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { CarPictureService } from '../../../core/services/car-picture.service';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { PageComponent } from '../../layout/page/page.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VehicleDefinitionComponent } from '../../business/vehicle-definition/vehicle-definition.component';
import { NoValue, RadioCardsComponent } from 'ngx-mobilite-material';
import { CardValue } from 'ngx-mobilite-material/lib/models/core.model';
import { UserType } from '../../../core/models/user-type.model';
import { map, Observable, startWith, Subscription, tap } from 'rxjs';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { DataProviderService } from '../../../core/services/data-provider.service';
import { VehicleData } from '../../../core/models/vehicle-data.model';
import { Router, RouterLink } from '@angular/router';
import { TokenInformation } from '../../../core/models/token-information.model';

@Component({
  selector: 'tes-part-pro-choice',
  standalone: true,
  imports: [
    NgOptimizedImage,
    PageComponent,
    TranslateModule,
    VehicleDefinitionComponent,
    RadioCardsComponent,
    AsyncPipe,
    ReactiveFormsModule,
    MatButton,
    RouterLink
  ],
  templateUrl: './part-pro-choice.component.html',
  styleUrl: './part-pro-choice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PartProChoiceComponent implements OnDestroy {
  urlFromToken!: string;
  tokenData!: TokenInformation;
  availableVersionCards!: CardValue[];
  tokenData$ = this.dataProviderService.get().pipe(
    tap(tokenData => {
      const availableVersions = tokenData.teslaVehicleDetails;
      const firstVersion = availableVersions[0];
      this.vehicleData = {
        model: tokenData.model,
        ...(availableVersions.length === 1 ? { literalLabel: firstVersion.literalLabel } : {}),
        totalPrice: tokenData.totalPrice
      };
      this.urlFromToken = this.carPictureService.getUrl({
        options: firstVersion.options?.join(','),
        model: tokenData.modelCode
      });
      this.tokenData = tokenData;
      if (availableVersions.length > 1) {
        this.availableVersionCards = availableVersions.map(version => {
          const isPropulsion = version.literalLabel[0].includes('Propulsion');
          const pivotIndex = isPropulsion ? 1 : 2;
          return {
            title: version.literalLabel.slice(0, pivotIndex).join('\n'),
            value: `${version.natCode}`,
            detail: version.literalLabel[pivotIndex],
            detailExpanded: version.literalLabel.slice(pivotIndex).join('\n')
          };
        });
      } else {
        this.versionChoice.setValue(`${firstVersion.natCode}`);
      }
    })
  );
  userTypeCardData$: Observable<CardValue[]> = this.translateService.onLangChange.pipe(
    startWith(''),
    map(() =>
      Object.keys(UserType).map(key => ({
        value: key,
        title: this.translateService.instant(`part-pro-choice.cards.titles.${key.toLowerCase()}`),
        detail: this.translateService.instant(`part-pro-choice.cards.details.${key.toLowerCase()}`)
      }))
    )
  );
  userTypeChoice = new FormControl<UserType | null>(null, { validators: Validators.required });
  versionChoice = new FormControl<string>('', { validators: Validators.required });
  vehicleData: VehicleData = {} as VehicleData;

  isUserPrivate$: Observable<{ value: boolean }> = this.userTypeChoice.valueChanges.pipe(
    startWith(UserType.PRIVATE),
    map(userType => ({ value: userType === UserType.PRIVATE }))
  );

  subscription: Subscription | NoValue;

  get randomUrl(): string {
    return this.carPictureService.getUrl();
  }

  @ViewChild('contractFormContainer') set contractFormContainer(containerRef: ElementRef) {
    if (containerRef) {
      const contactFormComponent = document.createElement('mobi-box-contact-form');
      contactFormComponent.setAttribute('nonce', 'NGINX_CSP_NONCE');
      contactFormComponent.setAttribute('business', 'true');
      contactFormComponent.setAttribute('id-pos', 'tesla_cetelem');
      contactFormComponent.setAttribute('brand', 'TESLA');
      contactFormComponent.setAttribute('model', this.tokenData.model);
      contactFormComponent.setAttribute('vehicle-id', `${this.versionChoice.value}`);
      contactFormComponent.setAttribute('category', 'A');
      contactFormComponent.setAttribute('amount', `${this.tokenData.totalPrice}`);
      contactFormComponent.setAttribute('energy', 'C');
      contactFormComponent.setAttribute('rn-number', this.tokenData.rnNumber);
      contactFormComponent.setAttribute('is-standalone', 'true');

      this.subscription = this.versionChoice.valueChanges.subscribe(value => {
        contactFormComponent.setAttribute('vehicle-id', `${value}`);
      });

      contactFormComponent.addEventListener('formValidated', () => {
        this.router.navigateByUrl('/finalize');
      });

      const container = containerRef.nativeElement;
      if (container) {
        container.append(contactFormComponent);
        this.cdr.markForCheck();
      }
    }
  }

  constructor(
    private readonly carPictureService: CarPictureService,
    private readonly dataProviderService: DataProviderService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
