import { APP_INITIALIZER, enableProdMode, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AppConfig, initConfig } from './app/app.config';
import { loadTranslations, TranslationInitPreload } from './app/translate.config';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
  withHashLocation,
  withInMemoryScrolling
} from '@angular/router';
import { APP_ROUTES } from './app/app-routes';
import { MAT_DATE_LOCALE } from '@angular/material/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import LocaleEn from '@angular/common/locales/en';
// eslint-disable-next-line @typescript-eslint/naming-convention
import LocaleFr from '@angular/common/locales/fr';
import { apikeyInterceptor } from './app/apikey.interceptor';

// to use the local format like Date, Currency...
registerLocaleData(LocaleEn, 'en');
registerLocaleData(LocaleFr, 'fr');

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): string {
    return `[Missing translation for key:  ${params.key}]`;
  }
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      APP_ROUTES,
      withDebugTracing(),
      withComponentInputBinding(),
      withHashLocation(),
      withInMemoryScrolling({ anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })
    ),
    provideAnimations(),
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    provideHttpClient(withInterceptors([apikeyInterceptor])),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MyMissingTranslationHandler
        },
        useDefaultLang: true,
        defaultLanguage: 'fr'
      })
    ),
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true
    },
    TranslationInitPreload,
    {
      provide: APP_INITIALIZER,
      useFactory: loadTranslations,
      deps: [TranslationInitPreload],
      multi: true
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'fr-FR' }
  ]
}).catch(err => console.log(err));
