import { Component } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgOptimizedImage } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'tes-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [TranslateModule, MatMenuModule, NgOptimizedImage, MatButton],
  standalone: true
})
export class HeaderComponent {
  constructor(public readonly translate: TranslateService) {}
}
