import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageComponent } from '../../layout/page/page.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgOptimizedImage } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'tes-finalize',
  standalone: true,
  imports: [PageComponent, TranslateModule, NgOptimizedImage, MatButton],
  templateUrl: './finalize.component.html',
  styleUrl: './finalize.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FinalizeComponent {}
