<tes-page [title]="'part-pro-choice.title' | translate" class="part-pro-choice">
  @if (tokenData$ | async) {
    <img [ngSrc]="urlFromToken" alt="" [width]="'720'" [height]="'405'"/>
  } @else {
    <img [ngSrc]="randomUrl" alt="" [width]="'720'" [height]="'405'"/>
  }
  <p class="part-pro-choice_command">{{ 'part-pro-choice.command' | translate }}</p>
  <tes-vehicle-definition [vehicleData]="vehicleData"/>
  <p class="part-pro-choice_choose">{{ 'part-pro-choice.choose' | translate }}</p>
  <ml-radio-cards class="bold-label" [label]="'part-pro-choice.choose-part-pro' | translate"
                  [formControl]="userTypeChoice" [cardItems]="userTypeCardData$ | async"></ml-radio-cards>
  @if (availableVersionCards) {
    <ml-radio-cards class="bold-label" [label]="'part-pro-choice.choose-version' | translate"
                    [formControl]="versionChoice" [cardItems]="availableVersionCards"></ml-radio-cards>
  }
  @if ((isUserPrivate$ | async); as isUserPrivate) {
    @if (isUserPrivate.value) {
      <button id="goToSimulate" mat-flat-button color="primary" [disabled]="!userTypeChoice.valid || !versionChoice.valid">
        <a class="do-not-display-as-a-link" [routerLink]="['/simulate']" [state]="{ natCode: versionChoice.value }">
          {{ 'part-pro-choice.simulate' | translate }}
        </a>
      </button>
    } @else if (!isUserPrivate.value && versionChoice.valid) {
      <div #contractFormContainer></div>
    }
  }
</tes-page>
