import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DataProviderService } from '../../../core/services/data-provider.service';
import { VehicleData } from '../../../core/models/vehicle-data.model';
import { CarPictureService } from '../../../core/services/car-picture.service';
import { tap } from 'rxjs';
import { VehicleDefinitionComponent } from '../../business/vehicle-definition/vehicle-definition.component';
import { PageComponent } from '../../layout/page/page.component';
import { TokenInformation } from '../../../core/models/token-information.model';
import { Router } from '@angular/router';

@Component({
  selector: 'tes-simulate',
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, AsyncPipe, VehicleDefinitionComponent, PageComponent],
  templateUrl: './simulate.component.html',
  styleUrl: './simulate.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SimulateComponent {
  urlFromToken!: string;
  vehicleData: VehicleData = {} as VehicleData;
  natCode: string;

  tokenData$ = this.dataProviderService.get().pipe(
    tap(tokenData => {
      const { modelCode, model, totalPrice, teslaVehicleDetails } = tokenData;
      const { literalLabel, options } =
        teslaVehicleDetails.find(vehicle => `${vehicle.natCode}` === this.natCode) || {};
      this.vehicleData = { model, literalLabel, totalPrice };
      this.urlFromToken = this.carPictureService.getUrl({ options: options?.join(','), model: modelCode });
      this.createDetailComponent(tokenData);
    })
  );

  get randomUrl(): string {
    return this.carPictureService.getUrl();
  }

  constructor(
    private readonly carPictureService: CarPictureService,
    private readonly dataProviderService: DataProviderService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router
  ) {
    this.natCode = this.router.getCurrentNavigation()?.extras.state?.natCode;
  }

  private createDetailComponent(tokenData: TokenInformation): void {
    const detailComponent = document.createElement('mobi-box-detail');
    detailComponent.setAttribute('nonce', 'NGINX_CSP_NONCE');
    detailComponent.setAttribute('id-pos', 'tesla_cetelem');
    detailComponent.setAttribute('brand', 'TESLA');
    detailComponent.setAttribute('model', tokenData.model);
    detailComponent.setAttribute('vehicle-id', this.natCode);
    detailComponent.setAttribute('category', 'A');
    detailComponent.setAttribute('amount', `${tokenData.totalPrice}`);
    detailComponent.setAttribute('energy', 'C');
    detailComponent.setAttribute('rn-number', tokenData.rnNumber);
    detailComponent.setAttribute('email-activated', 'false');

    const page = document.getElementById('page');
    if (page) {
      page.append(detailComponent);
      this.cdr.markForCheck();
    }
  }
}
