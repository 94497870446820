import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { TokenInformation } from '../models/token-information.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeslaService {
  baseUrl = [this.appConfig.url, this.appConfig.basepath, 'tesla'].filter(item => !!item).join('/');

  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfig
  ) {}

  getTokenData(token: string): Observable<TokenInformation> {
    return this.httpClient.get<TokenInformation>(
      token
        ? `${this.baseUrl}/fetch-from-token?token=${token}&locale=fr_FR`
        : `${this.baseUrl}/fetch-from-token/stub?token=coucou`
    );
  }
}
